export const Authlogin = async (token) => {
    try {
      if (!token) {
        throw new Error("Token is required for authentication.");
      }
  
      const baseUrl = window.location.href; 
      const apiUrl = `${baseUrl}/verify_token/${token}`; 
  
      const response = await fetch(apiUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.status === 200) {
        return { success: true }; 
      } else {
        throw new Error(`API call failed with status: ${response.status}`); 
      }
    } catch (error) {
      console.error("Error in Authlogin:", error.message);
      return { success: false, error: error.message }; 
    }
  };