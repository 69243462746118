import React from "react";
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import PropTypes from "prop-types";
import { CometChat } from "@cometchat/chat-sdk-javascript";

import {
  CometChatAvatar,
  CometChatConfirmDialog,
  CometChatUserPresence,
} from "../../Shared";

import { CometChatContext } from "../../../util/CometChatContext";
import * as enums from "../../../util/enums.js";

import Translator from "../../../resources/localization/translator";

import {
  modalRowStyle,
  nameColumnStyle,
  avatarStyle,
  nameStyle,
  roleStyle,
  scopeColumnStyle,
  scopeIconStyle,
  actionColumnStyle,
  banIconStyle,
  kickIconStyle,
  scopeWrapperStyle,
  scopeSelectionStyle,
  Roles,
  scopeicondiv,
  scopeiconclear,
  scopeicondone,
  scopeIconStyleDone,
  optionSelect,
} from "./style";

import scopeIcon from "./resources/edit.svg";
import doneIcon from "./resources/done.svg";
import clearIcon from "./resources/close.svg";
import banIcon from "./resources/ban-member.svg";
import newClearsvg from "./resources/newClearsvg.svg";
import newDonesvg from "./resources/newDonesvg.svg";
import kickIcon from "./resources/delete.svg";
import kicknewicon from "./resources/kick.svg";
import { getDataFromParams } from "../../../../getUrlParams.js";
import { presenceStyle } from "../../Shared/CometChatUserPresence/style.js";
import "../../../../index.scss";
const UserTag = getDataFromParams("setUserTags");
class CometChatViewGroupMemberListItem extends React.Component {
  static contextType = CometChatContext;

  constructor(props, context) {
    super(props, context);
    
    this.state = {
      showChangeScope: false,
      scope: this.props.member.scope,
      showConfirmDialog: false,
      showoption: true,
    };

    this.changeScopeDropDown = (
      <select
        className="members-scope-select"
        onChange={this.scopeChangeHandler}
        value={this.state.scope}
      ></select>
    );

    this.roles = context.roles;
  }

  toggleChangeScope = (flag = true) => {
    this.setState({
      showChangeScope: flag,
      hasScopeChanged: false,
      scope: flag == false ? this.props.member.scope : this.state.scope,
    });
  };

  scopeChangeHandler = (event) => {
    this.setState({
      scope: event.target.value,
      hasScopeChanged: true,
    });
  };

  handleKickClick = () => {
    this.setState({ showConfirmDialog: true });
  };

  // Method to handle confirmation dialog close and action
  handleConfirmClose = (isConfirmed) => {
    if (isConfirmed) {
      // If the action is confirmed, trigger the LeaveGroup action
      window.parent.postMessage(
        {
          action: "LeaveGroup",
          grpid: this.props.member.guid,
          memguids: this.props.member.uid,
        },
        "*"
      );

      // Trigger the actionGenerated method
      this.props.actionGenerated(
        enums.ACTIONS["KICK_GROUP_MEMBER"],
        this.props.member
      );
    }

    this.setState({ showConfirmDialog: false }); // Close the dialog
  };

  updateMemberScope = () => {
    this.props.actionGenerated(
      enums.ACTIONS["CHANGE_SCOPE_GROUP_MEMBER"],
      this.props.member,
      this.state.scope,
      // Post the message to the parent window
      window.parent.postMessage(
        {
          action: "RoleChange",
          Groupid: this.props.member.guid,
          GroupUserUid: this.props.member.uid,
          PreviousScope: this.props.member.scope,
        },
        "*" // You can replace '*' with the specific origin of the parent window for better security
      )
    );
    this.toggleChangeScope();
  };

  toggleTooltip = (event, flag) => {
    const elem = event.currentTarget;

    if (elem.classList.contains("name")) {
      const scrollWidth = elem.scrollWidth;
      const clientWidth = elem.clientWidth;

      if (scrollWidth <= clientWidth) {
        return false;
      }
    }

    if (flag) {
      elem.setAttribute("title", this.decryptedName(this.props.member.name));
    } else {
      elem.removeAttribute("title");
    }
  };

  decrypt(message) {
    let shift = 23;
    let decryptedMessage = "";
    let specialValue = "#$^%&*#$";
    if (!message.includes(specialValue)) {
      return message;
    } else {
      message = message.replaceAll("#$^%&*#$", "");
      for (let i = 0; i < message.length; i++) {
        const charCode = message.charCodeAt(i);
        if (charCode >= 65 && charCode <= 90) {
          decryptedMessage += String.fromCharCode(
            ((charCode - 65 + shift) % 26) + 65
          );
        } else if (charCode >= 97 && charCode <= 122) {
          decryptedMessage += String.fromCharCode(
            ((charCode - 97 + shift) % 26) + 97
          );
        } else {
          decryptedMessage += message[i];
        }
      }
      return decryptedMessage;
    }
  }

  decryptedName(name) {
    return this.decrypt(name);
  }
  render() {
    let editClassName = "";

    let name = this.props.member.name;
    let trunnewname= this.decryptedName(name)
    const truncatedName = trunnewname.length > 13 ? `${name.slice(0, 12)}...` : name;
    
    
    let scope = (
      <span css={roleStyle()}>
        {this.context.roles[this.props.member.scope]}
      </span>
    );
    let changescope = null;
    let ban = (
      <i
        css={banIconStyle(banIcon, this.context)}
        title={Translator.translate("BAN", this.context.language)}
        onClick={() => {
          this.props.actionGenerated(
            enums.ACTIONS["BAN_GROUP_MEMBER"],
            this.props.member
          );
        }}
      ></i>
    );
    // let kick = (
    //   <i
    //     css={kickIconStyle(kickIcon, this.context)}
    //     title={Translator.translate("KICK", this.context.language)}
    //     onClick={() => {
    //       this.props.actionGenerated(
    //         enums.ACTIONS["KICK_GROUP_MEMBER"],
    //         this.props.member,
    //         {console.log(this.props.member)}
    //       );
    //     }}
    //   ></i>
    // );

    //  let kick = (
    //   <i
    //     css={kickIconStyle(kickIcon, this.context)}
    //     title={Translator.translate("KICK", this.context.language)}
    //     onClick={() => {
    //       window.parent.postMessage(
    //         {
    //           action: 'LeaveGroup',
    //           grpid: this.props.member.guid,
    //           memguids: this.props.member.uid
    //         },
    //         "*"
    //       );

    //       // Trigger the actionGenerated method
    //       this.props.actionGenerated(
    //         enums.ACTIONS["KICK_GROUP_MEMBER"],
    //         this.props.member
    //       );
    //     }}
    //   ></i>
    // );

    let kick = (
      <i
        css={kickIconStyle(kicknewicon, this.context)}
        title={Translator.translate("KICK", this.context.language)}
        onClick={this.handleKickClick}
      ></i>
    );

    if (this.state.showoption) {
      let options = (
        <React.Fragment>
          <option value={CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT} className="option_select" css={optionSelect()}>
            {this.context.roles[CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT]}
          </option>
          {/* <option value={CometChat.GROUP_MEMBER_SCOPE.MODERATOR}>
            {this.context.roles[CometChat.GROUP_MEMBER_SCOPE.MODERATOR]}
          </option> */}
          <option value={CometChat.GROUP_MEMBER_SCOPE.ADMIN}  className="option_select" css={optionSelect()}>
            {this.context.roles[CometChat.GROUP_MEMBER_SCOPE.ADMIN]}
          </option>
        </React.Fragment>
      );

      if (
        this.context.item.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR &&
        this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
      ) {
        options = (
          <React.Fragment>
            <option value={CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT}>
              {this.context.roles[CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT]}
            </option>
            <option value={CometChat.GROUP_MEMBER_SCOPE.MODERATOR}>
              {this.context.roles[CometChat.GROUP_MEMBER_SCOPE.MODERATOR]}
            </option>
          </React.Fragment>
        );
      }

      changescope = (
        <div
          css={scopeWrapperStyle(this.state.hasScopeChanged)}
          className="scope__wrapper"
        >
          <select
            css={scopeSelectionStyle(this.state.hasScopeChanged)}
            className="scope__select"
            onChange={this.scopeChangeHandler}
            value={this.state.scope}
            defaultValue={this.props.member.scope} // Controlled component
          >
            {options}
          </select>
          {this.state.hasScopeChanged && (
            <>
              <div css={scopeiconclear()} className="scopeicondiv">
                <i
                  css={scopeIconStyle(
                    newClearsvg,
                    this.context,
                    this.state.hasScopeChanged
                  )}
                  title={Translator.translate(
                    "CHANGE_SCOPE",
                    this.context.language
                  )}
                  onClick={() => this.toggleChangeScope(false)}
                ></i>
              </div>
              <div css={scopeicondone()} className="scopeicondiv">
                <i
                  css={scopeIconStyleDone(
                    newDonesvg,
                    this.context,
                    this.state.hasScopeChanged
                  )}
                  title={Translator.translate(
                    "CHANGE_SCOPE",
                    this.context.language
                  )}
                  onClick={this.updateMemberScope}
                ></i>
              </div>
            </>
          )}
        </div>
      );
      // return changescope;
    } else {
      if (
        this.context.item.scope === CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT
      ) {
        changescope = scope;
      } else {
        changescope = (
          <React.Fragment>
            {/* {scope} */}
            {/* <i
              css={scopeIconStyle(scopeIcon, this.context)}
              title={Translator.translate(
                "CHANGE_SCOPE",
                this.context.language
              )}
              onClick={() => this.toggleChangeScope(true)}
            ></i> */}
          </React.Fragment>
        );
      }
    }

    //disable change scope, kick, ban of group owner
    if (this.context.item.owner === this.props.member.uid) {
      scope = (
        <span css={roleStyle()}>
          {Translator.translate("OWNER", this.context.language)}
        </span>
      );
      changescope = scope;
      ban = null;
      kick = null;
    }

    //disable change scope, kick, ban of self
    if (this.props.loggedinuser.uid === this.props.member.uid) {
      name = Translator.translate("YOU", this.context.language);
      changescope = scope;
      ban = null;
      kick = null;
    }

    //if the loggedin user is moderator, don't allow to change scope, ban, kick group moderators or administrators
    if (
      this.context.item.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR &&
      (this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN ||
        this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.MODERATOR)
    ) {
      changescope = scope;
      ban = null;
      kick = null;
    }

    //if the loggedin user is administrator but not group owner, don't allow to change scope, ban, kick group administrators
    if (
      this.context.item.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN &&
      this.context.item.owner !== this.props.loggedinuser.uid &&
      this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN
    ) {
      changescope = scope;
      ban = null;
      kick = null;
    }

    let editAccess = null;
    //if the loggedin user is participant, don't show the option to change scope, ban, or kick group members
    if (this.context.item.scope === CometChat.GROUP_MEMBER_SCOPE.PARTICIPANT) {
      editAccess = null;
      editClassName = "true";
    } else {
      const showKickOption =
        this.props.loggedinuser.uid === this.props.member.uid ||
        this.state.hasScopeChanged;
        const isEdit= this.context.item.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN &&
        this.context.item.owner !== this.props.loggedinuser.uid &&
        this.props.member.scope === CometChat.GROUP_MEMBER_SCOPE.ADMIN
      editAccess = (
        <React.Fragment>
          {/* Uncomment the ban div if needed */}
          {/* <div css={actionColumnStyle(this.context)} className="ban">
          {ban}
        </div> */}
          {!showKickOption && !isEdit && (
            <div css={actionColumnStyle(this.context)} className="kick">
              {kick}
            </div>
          )}
        </React.Fragment>
      );

      /**
       * if kick and ban feature is disabled
       */
      if (
        this.props.enableBanGroupMembers === false &&
        this.props.enableKickGroupMembers === false
      ) {
        editAccess = null;
      } else if (this.props.enableBanGroupMembers === false) {
        //if ban feature is disabled
        editAccess = (
          <div css={actionColumnStyle(this.context)} className="kick">
            {kick}
          </div>
        );
      } else if (this.props.enableKickGroupMembers === false) {
        //if kick feature is disabled
        editAccess = (
          <div css={actionColumnStyle(this.context)} className="ban">
            {ban}
          </div>
        );
      }

      /**
       * if promote_demote_members feature is disabled
       */
      if (this.props.enableChangeScope === false) {
        changescope = scope;
      }
    }

    let userPresence = (
      <CometChatUserPresence status={this.props.member.status} />
    );
    let groupname = this.decryptedName(this.context?.item?.name);

    return (
      <div css={modalRowStyle(this.context)} className="content__row">
        <div
          css={nameColumnStyle(this.context, editClassName)}
          className="userinfo"
        >
          <div
            css={avatarStyle(this.context, editClassName)}
            className="thumbnail"
            onMouseEnter={(event) => this.toggleTooltip(event, true)}
            onMouseLeave={(event) => this.toggleTooltip(event, false)}
          >
            {/* <CometChatAvatar user={this.props.member} />
                  {UserTag !== "super-admin" ? userPresence : null} */}
            <div style={{ position: "relative", display: "inline-block" }}>
              <CometChatAvatar user={this.props.member} />
              {/* <div style={presenceStyle({ status: this.props.member.status})}></div> */}
              <div style={presenceStyle(this.props, this.context)}></div>
            </div>
          </div>
          <div
            css={nameStyle(this.context, editClassName)}
            className="name"
            onMouseEnter={(event) => this.toggleTooltip(event, true)}
            onMouseLeave={(event) => this.toggleTooltip(event, false)}
          >
            {/* {this.decryptedName(name)}  */}
            {this.decryptedName(truncatedName)}
            <span css={Roles()}>
            {this.props?.member?.role !== "prospect"
  ? this.props?.member?.role === "ambassador" &&
    this.props?.member?.metadata?.AffiliationWithInstitution
    ? `(${this.props?.member?.metadata?.AffiliationWithInstitution})`
    : `(${this.props?.member?.role})`
  : ""}

              </span>
          </div>
        </div>
        <div css={scopeColumnStyle(this.context)} className="scope">
        {this.context.item.scope === "admin" ? changescope : scope}
        </div>
        {editAccess}
        {this.state.showConfirmDialog && (
          <CometChatConfirmDialog
            {...this.props} // Pass all necessary props
            onClick={this.handleConfirmClose}
            ShouldLeaveDialog={true} // Handle confirm dialog action
            RemoveDialog={true}
            membername={this.decryptedName(this.props?.member?.name)}
            GroupName={groupname}
            message={Translator.translate(
              "Remove_CONFIRM",
              this.context.language // Assuming you're using context for language
            )}
            confirmButtonText={Translator.translate(
              "Remove",
              this.context.language
            )}
            cancelButtonText={Translator.translate(
              "Don’t remove",
              this.context.language
            )}
          />
        )}
      </div>
    );
  }
}

// Specifies the default values for props:
CometChatViewGroupMemberListItem.defaultProps = {
  loggedinuser: {},
  enableChangeScope: false,
};

CometChatViewGroupMemberListItem.propTypes = {
  loggedinuser: PropTypes.shape(CometChat.User),
  enableChangeScope: PropTypes.bool,
};

export { CometChatViewGroupMemberListItem };
