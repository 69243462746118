import React from "react";

function Unauthlogin() {
  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
    fontFamily: "Arial, sans-serif",
  };

  const boxStyle = {
    textAlign: "center",
    padding: "20px",
    border: "1px solid #ccc",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const headingStyle = {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
  };

  const paragraphStyle = {
    fontSize: "16px",
    color: "#666",
    marginBottom: "15px",
  };

 

  return (
    <div style={containerStyle}>
      <div style={boxStyle}>
        <h1 style={headingStyle}>Unauthorized Access</h1>
        <p style={paragraphStyle}>
          You are not authorized to view this page.
        </p>
      </div>
    </div>
  );
}

export default Unauthlogin;
