export const modalRowStyle = (context) => {
	return {
		// borderLeft: `1px solid ${context.theme.borderColor.primary}`,
		// borderRight: `1px solid ${context.theme.borderColor.primary}`,
		// borderBottom: `1px solid ${context.theme.borderColor.primary}`,
		display: "flex",
		width: "100%",
		fontSize: "14px",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		padding: "8px",
	};
};

export const nameColumnStyle = (context, participantView) => {
	const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

	const widthProp = participantView
		? {
				width: "calc(100% - 180px)",
				[mq[1]]: {
					width: "calc(100% - 140px)",
				},
				[mq[2]]: {
					width: "calc(100% - 180px)",
				},
		  }
		: {
				width: "calc(100% - 260px)",
				[mq[1]]: {
					width: "calc(100% - 220px)",
				},
				[mq[2]]: {
					width: "calc(100% - 260px)",
				},
				[mq[3]]: {
					width: "calc(100% - 240px)",
				},
		  };

	return {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		marginRight:'50px',
		...widthProp,
	};
};

export const avatarStyle = (context, participantView) => {
	const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

	const marginProp = participantView
		? {
				marginRight: "8px",
		  }
		: {
				marginRight: "8px",
				[mq[1]]: {
					marginRight: "0",
				},
		  };

	return {
		width: "36px",
		height: "36px",
		flexShrink: "0",
		...marginProp,
	};
};

export const nameStyle = (context, participantView) => {
	const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

	const widthProp = participantView
		? {
				width: "100%",
		  }
		: {
				width: "calc(100% - 50px)",
		  };

	const displayProp = participantView
		? {
				display: "inline",
				[mq[1]]: {
					display: "inline",
				},
		  }
		: {
				display: "inline",
				[mq[1]]: {
					display: "none",
				},
		  };

	return {
		margin: "8px 0",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
		color:"#494F73",
		fontFamily: '"Lexend Deca"',
		fontSize:"14px",
		fontStyle:'normal',
		fontWeight:500,
		lineHeight:'24px',
		...widthProp,
		...displayProp,
	};
};

export const scopeColumnStyle = (context) => {
	const mq = context.theme.breakPoints.map((x) => `@media ${x}`);

	return {
    // padding: "8px 3px 8px 10px",
    // borderRadius: "10px",
    // border: "1px solid  #CBD6E2",
    // background:  "#F5F8FA",
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "flex-start",
		// width: "130px",
		img: {
			width: "24px",
			height: "24px",
			cursor: "pointer",
		},
		[mq[1]]: {
			width: "140px",
		},
		[mq[2]]: {
			width: "180px",
		},
		[mq[3]]: {
			width: "120px",
		},
	};
};

export const scopeWrapperStyle = (hasScopeChanged) => {
	let scopechange=hasScopeChanged

	
	return {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center",
		// width: "100%",
		transition: "opacity .1s linear",
		img: {
			margin: "0px 4px",
		},
		borderRadius:scopechange?'12px':'',
		background:scopechange ? '#494F73': '',
		padding:scopechange?'5px':'',
		// width:scopechange ? '199px' : '',

	};
};

// export const scopeSelectionStyle = () => {
// 	return {
// 		// width: "65%",
// 		// border: "0",
// 		// boxShadow: "rgba(20, 20, 20, 0.04) 0 0 0 1px inset",
// 		// borderRadius: "8px",
// 		// backgroundColor: `rgba(20, 20, 20, 0.04)`,
// 		// padding: "8px",
// 		// color: `rgba(20, 20, 20, 0.6)`,
// 		// float: "left",
// 			  padding: "10px 3px 10px 10px",
//     borderRadius: "10px",
//     border: "1px solid  #CBD6E2",
//     background:  "#F5F8FA",
// 		display: "flex",
// 		flexDirection: "row",
// 		alignItems: "center",
// 		justifyContent: "flex-start",
// 		width: "130px",
// 		color:'#494F73',
// 	};
// };


export const scopeSelectionStyle = (hasScopeChanged) => {
	let scopechange=hasScopeChanged
	
	return {
	  fontFamily:"Lexend Deca",
	  fontSize:'14px',
	  fontStyle:'normal',
	  fontWeight:300,
	  lineHeight:'24px',
	  padding: "10px 3px 10px 10px",
	  borderRadius:"10px",
	  border: scopechange ? "1px solid  #CBD6E2": '',
	  background: "#F5F8FA",
	  display: "flex",
	  flexDirection: "row",
	  alignItems: "center",
	  justifyContent: "flex-start",
	  width: "130px",
	  color: '#494F73', // Text color
	//    appearance: "none", // Remove the default dropdown arrow
	//   paddingRight: "30px", // Space for the custom arrow
	  marginRight:scopechange ? '0px': '30px',
	};

  };
  
export const scopeIconStyle = (img, context,hasScopeChanged) => {
	let scopechange=hasScopeChanged
	return {
		width: "24px",
		height: "24px",
		display: "inline-block",
		cursor: "pointer",
	 WebkitMask: `url(${img}) center center no-repeat`,
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: hasScopeChanged ? "#DF0C34" : context.theme.secondaryTextColor, // Dynamic color
	};
};

export const scopeIconStyleDone = (img, context,hasScopeChanged) => {
	let scopechange=hasScopeChanged
	return {
		width: "24px",
		height: "24px",
		display: "inline-block",
		cursor: "pointer",
	 WebkitMask: `url(${img}) center center no-repeat`,
    mask: `url(${img}) center center no-repeat`,
    backgroundColor: hasScopeChanged ? "#3AA352" : context.theme.secondaryTextColor, // Dynamic color
	};
};

export const roleStyle = () => {
	return {
		fontSize: "14px",
		maxWidth: "calc(100% - 20px)",
		color:  "#494F73",
		fontFamily:'Lexend Deca',
		fontStyle:'normal',
		fontWeight:500,
		lineHeight:'24px',
	};
};

export const actionColumnStyle = (context) => {
	
	const mq = context.theme.breakPoints.map((x) => `@media ${x}`);
  
	return {
	  width: "40px",
	  display: "flex", // Proper syntax for `display` property
	  flexDirection: "column", // Proper CSS property name
	  justifyContent: "center",
	  alignItems: "center",
	//   gap: "-2px",
	  borderRadius: "10px",
	  background: "#FCE7EB",
	  height: "40px",
	  [mq[1]]: {
		width: "40px",
	  },
	  [mq[2]]: {
		width: "40px",
	  },
	};
  };
  
export const banIconStyle = (img, context) => {
	return {
		width: "24px",
		height: "24px",
		display: "inline-block",
		cursor: "pointer",
		mask: `url(${img}) center center no-repeat`,
		backgroundColor: `${context.theme.secondaryTextColor}`,
	};
};

export const kickIconStyle = (img, context) => {
	return {
		width: "24px",
		height: "24px",
		display: "inline-block",
		cursor: "pointer",
		background: `url(${img}) center center no-repeat`,
	};
};
export const Roles = () => {
	return {
	  color: "#494F73",
	  fontFamily: "Lexend Deca",
	  fontSize: "12px",
	  fontStyle: "normal",
	  fontWeight: 300,
	  lineHeight: "18px", // 150%
	  textTransform: "capitalize",
	  marginLeft:'3px',
	  
	};
  };
  
  export const scopeiconclear = ()=>{

	return{
		display: 'flex',
width: '40px',
height: '40px',
flexDirection:'column',
justifyContent:'center',
alignItems:'center',
marginLeft:'10px',
borderRadius:'10px',
background:' #FCE7EB',
	}
  }
  export const scopeicondone = ()=>{	
	return{
		display: 'flex',
width: '40px',
height: '40px',
flexDirection:'column',
justifyContent:'center',
alignItems:'center',
marginLeft:'10px',
borderRadius:'10px',
background:' #F0F8F2',

	}
  }
  export const optionSelect = () => {
	return {
	  color: 'var(--TL-Main, #494F73)', 
	  fontFamily: '"Lexend Deca"', 
	  fontSize: '14px',
	  fontStyle: 'normal',
	  fontWeight: 300,
	  lineHeight: '24px', 
	};
  };
  